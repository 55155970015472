<mat-form-field class="w-100">
  <mat-label>Schlüsselnummer</mat-label>
  <mat-select [formControl]="control" (openedChange)="resetScroll()">
    <mat-option disabled *ngIf="loading"> Lade… </mat-option>

    <mat-option *ngIf="!loading">
      <ngx-mat-select-search [formControl]="searchTerm" />
    </mat-option>
    <cdk-virtual-scroll-viewport class="autocomplete__viewport" [itemSize]="25">
      <mat-option
        *cdkVirtualFor="
          let option of filteredWastes$ | async;
          trackBy: identify
        "
        [value]="option.gtin"
      >
        {{ option.label }}
      </mat-option>
    </cdk-virtual-scroll-viewport>

    <!-- Dummy element because the virtual scroll can hide the selected element which breaks the select box -->
    <ng-container *ngIf="selectedElement$ | async as selectedElement">
      <mat-option [value]="selectedElement.gtin" [style.display]="'none'">
        {{ selectedElement.label }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>

<button id="showExplainer" mat-button (click)="showExplainer = !showExplainer">
  <mat-icon>info</mat-icon>
  Was bedeuten die Zusatzziffern zur Abfallspezifizierung?
</button>

<app-expander-animation id="showExplainer" [isExpanded]="showExplainer">
  <app-row>
    <app-col>
      <app-definition-list class="list">
        <dt>77</dt>
        <dd>gefährlich kontaminiert</dd>
        <dt>88</dt>
        <dd>ausgestuft</dd>
        <dt>91</dt>
        <dd>verfestigt, immobilisiert oder stabilisiert</dd>
        <dt>1</dt>
        <dd>(aus) behandeltes(m) Holz</dd>
        <dt>2</dt>
        <dd>
          (aus) nachweislich ausschließlich mechanisch behandeltes(m) Holz
        </dd>
        <dt>3</dt>
        <dd>(aus) behandeltes(m) Holz, schadstofffrei</dd>
        <dt>4</dt>
        <dd>Altholz stofflich</dd>
        <dt>10</dt>
        <dd>Anhang 1 Tabelle 1 der Recycling-Baustoffverordnung</dd>
        <dt>11</dt>
        <dd>gem. § 10b DVO 2008</dd>
        <dt>12</dt>
        <dd>bis 50 ppm PCB</dd>
        <dt>13</dt>
        <dd>größer als 50 bis 100 ppm PCB</dd>
        <dt>14</dt>
        <dd>größer als 100 bis 500 ppm PCB</dd>
        <dt>15</dt>
        <dd>größer als 500 bis 5000 ppm PCB</dd>
        <dt>16</dt>
        <dd>größer als 5000 ppm PCB</dd>
        <dt>17</dt>
        <dd>nur ausgewählte Abfälle aus Bau- und Abbruchmaßnahmen</dd>
        <dt>18</dt>
        <dd>
          nur Mischungen aus ausgewählten Abfällen aus Bau- und
          Abbruchmaßnahmen, ohne Mörtel- und Verputzanteile
        </dd>
        <dt>19</dt>
        <dd>
          Brandschutt von nicht gewerblichen Objekten, nicht gefährlich bei
          Ablagerung auf Massenabfalldeponien
        </dd>
        <dt>20</dt>
        <dd>Anhang 1 Tabelle 2 der Recycling-Baustoffverordnung</dd>
        <dt>21</dt>
        <dd>nur Einkehrsplitt als natürliche Gesteinskörnung</dd>
        <dt>23</dt>
        <dd>mineralische Rückstände aus der Aufbereitung von Baurest-massen</dd>
        <dt>29</dt>
        <dd>Bodenaushubmaterial mit Hintergrundbelastung</dd>
        <dt>30</dt>
        <dd>
          nicht verunreinigtes Bodenaushubmaterial der Qualitätsklasse A1 gemäß
          Bundes-Abfallwirtschaftsplan sowie daraus gewonnene, nicht
          verunreinigte Bodenbestandteile
        </dd>
        <dt>31</dt>
        <dd>
          nicht verunreinigtes Bodenaushubmaterial der Qualitätsklasse A2 gemäß
          Bundes-Abfallwirtschaftsplan sowie daraus gewonnene, nicht
          verunreinigte Bodenbestandteile
        </dd>
        <dt>32</dt>
        <dd>
          nicht verunreinigtes Bodenaushubmaterial der Qualitätsklasse A2-G
          gemäß Bundes-Abfallwirtschaftsplan sowie daraus gewonnene, nicht
          verunreinigte Bodenbestandteile
        </dd>
        <dt>33</dt>
        <dd>Aushubmaterial mit Inertabfalldeponiequalität</dd>
        <dt>33</dt>
        <dd>Bodenaushub</dd>
        <dt>34</dt>
        <dd>
          technisches Schüttmaterial, das weniger als 5 Vol-% bodenfremde
          Bestandteile enthält
        </dd>
        <dt>35</dt>
        <dd>
          technisches Schüttmaterial, ab 5 Vol-% bodenfremder Bestandteile
        </dd>
        <dt>36</dt>
        <dd>ölverunreinigtes Aushubmaterial, nicht gefährlich</dd>
        <dt>37</dt>
        <dd>sonstig verunreinigtes Aushubmaterial, nicht gefährlich</dd>
        <dt>37</dt>
        <dd>
          Bodenaushubmaterial sowie ausgehobenes Schüttmaterial, sonstig
          verunreinigt, nicht gefährlich
        </dd>
        <dt>38</dt>
        <dd>
          sonstige, nicht verunreinigte Bodenbestandteile der Qualitätsklasse A2
          gemäß Bundes-Abfallwirtschaftsplan
        </dd>
        <dt>39</dt>
        <dd>
          sonstige, nicht verunreinigte Bodenbestandteile der Qualitätsklasse BA
          gemäß Bundes-Abfallwirtschaftsplan oder Bodenaushubdeponiequalität
        </dd>
        <dt>40</dt>
        <dd>Asbestabfälle, Asbeststäube</dd>
        <dt>41</dt>
        <dd>künstliche Mineralfaserabfälle</dd>
        <dt>42</dt>
        <dd>Steinwolle</dd>
        <dt>43</dt>
        <dd>Glaswolle</dd>
        <dt>44</dt>
        <dd>Mischungen aus Steinwolle und Glaswolle</dd>
        <dt>45</dt>
        <dd>
          nicht verunreinigtes Bodenaushubmaterial eines Bau- oder
          Aushubvorhabens gemäß Kleinmengen-regelung
        </dd>
        <dt>50</dt>
        <dd>
          aus der thermischen Kupfermetallurgie (Erst- und Zweit-schmelze)
        </dd>
        <dt>51</dt>
        <dd>aus der thermischen Kupfer- oder Zinkmetallurgie</dd>
        <dt>60</dt>
        <dd>
          gemäß Bundes-Abfallwirtschaftsplan zulässig für Maßnahmen zur
          Bodenrekultivierung
        </dd>
        <dt>67</dt>
        <dd>Baum- und Strauchschnitt</dd>
        <dt>68</dt>
        <dd>aus der Verarbeitung von unbehandeltem Holz</dd>
        <dt>69</dt>
        <dd>Siebüberlauf zur Kompostierung</dd>
        <dt>70</dt>
        <dd>Rostaschen</dd>
        <dt>71</dt>
        <dd>Pflanzen-Rostaschen</dd>
        <dt>72</dt>
        <dd>Flugaschen</dd>
        <dt>73</dt>
        <dd>Pflanzen-Flugaschen</dd>
        <dt>74</dt>
        <dd>Feinstflugaschen</dd>
      </app-definition-list></app-col
    >
  </app-row>
</app-expander-animation>
