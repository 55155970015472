import { Component, OnInit, Optional, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { FormControl, NgControl, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteComponent } from 'src/app/components/shared/auto-complete/auto-complete.component';
import {
  InquiryService,
  InquiryWasteType,
} from 'src/app/shared/services/inquiry.service';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import {
  normalizeSearchTerm,
  wasteTypetoString,
} from 'src/app/shared/utils/adapters';
import { ExpanderAnimationComponent } from 'src/app/components/shared/expander-animation/expander-animation.component';
import { DefinitionListComponent } from 'src/app/components/shared/definition-list/definition-list.component';
import { RowComponent } from 'src/app/components/shared/row/row.component';
import { ColComponent } from 'src/app/components/shared/col/col.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { WrappedFormControlComponent } from 'src/app/components/shared/wrapped-form-control/wrapped-form-control.component';
import {
  CdkVirtualScrollViewport,
  ScrollingModule,
} from '@angular/cdk/scrolling';

interface ExtendedInquiryWasteType extends InquiryWasteType {
  label: string;
}

@Component({
  selector: 'app-sn-auto-complete',
  standalone: true,
  imports: [
    CommonModule,
    AppMaterialModule,
    ReactiveFormsModule,
    AutoCompleteComponent,
    ExpanderAnimationComponent,
    DefinitionListComponent,
    RowComponent,
    ColComponent,
    NgxMatSelectSearchModule,
    CdkVirtualScrollViewport,
    ScrollingModule,
  ],
  templateUrl: './sn-auto-complete.component.html',
  styleUrl: './sn-auto-complete.component.scss',
})
export class SnAutoCompleteComponent
  extends WrappedFormControlComponent
  implements OnInit
{
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  inquiryService = inject(InquiryService);
  showExplainer = false;
  searchTerm = new FormControl('');
  selectedElement$: Observable<ExtendedInquiryWasteType>;
  loading = true;

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
  }

  identify(_index: number, item: InquiryWasteType) {
    return item.gtin;
  }

  resetScroll() {
    this.viewPort.scrollToOffset(1, 'instant');
  }

  public wastes$ = this.inquiryService.getWasteTypes().pipe(
    map((wastes) =>
      wastes.map((waste) => ({
        ...waste,
        label: wasteTypetoString(waste),
      }))
    )
  );

  private _filter(value: string, options: ExtendedInquiryWasteType[]) {
    const normalizedValue = normalizeSearchTerm(value);
    if (!normalizedValue) return options;

    return options
      .filter((option) =>
        normalizeSearchTerm(option.label).includes(normalizedValue)
      )
      .sort(
        (a, b) =>
          normalizeSearchTerm(a.label).indexOf(normalizedValue) -
          normalizeSearchTerm(b.label).indexOf(normalizedValue)
      );
  }

  public filteredWastes$ = combineLatest([
    this.searchTerm.valueChanges.pipe(startWith(this.searchTerm.value)),
    this.wastes$,
  ]).pipe(map(([searchTerm, wastes]) => this._filter(searchTerm, wastes)));

  ngOnInit() {
    this.selectedElement$ = combineLatest([
      this.control.valueChanges.pipe(startWith(this.control.value)),
      this.wastes$,
    ]).pipe(
      map(([selectedGtin, wastes]) =>
        wastes.find((waste) => waste.gtin === selectedGtin)
      )
    );

    this.wastes$.subscribe(() => {
      this.loading = false;
    });
  }
}
